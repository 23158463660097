<template>
  <div style="width; 100%;" class="pa-10 d-flex aling-center justify-center">
    {{ $tc("global.not-found") }}
  </div>
</template>

<script>
export default {
  name: "NaoEncontrado",
};
</script>

<style></style>
